
@-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .fade-out {
    -webkit-animation: fade-out 0.3s ease-out both;
    animation: fade-out 0.3s ease-out both;
  }