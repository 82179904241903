body {
  color: #737491;
}

/**--all web- start-**/
.topbar.topbar-dark,
.navbar-tool .navbar-tool-badge {
  background-color: var(--primary);
}

.bg-gradient {
  background: var(--primary2) !important;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--primary2)),
    color-stop(30%, var(--primary)),
    to(#6bb9e7)
  ) !important;
  background: linear-gradient(
    to right,
    var(--primary2) 0%,
    var(--primary) 30%,
    #6bb9e7 100%
  ) !important;
}

.topbar.topbar-dark {
  padding: 0 15px;
}

.topbar a.social-btn {
  width: 30px;
  height: 30px;
}

.topbar a.social-btn i {
  line-height: 30px;
}

.accordion .accordion-heading > a.collapsed,
.accordion [data-toggle="collapse"] i {
  color: #4a4b65 !important;
}

.navbar-expand-lg .navbar-nav .nav-item.active > .nav-link:not(.disabled),
.navbar-expand-lg .navbar-nav .nav-item:hover > .nav-link:not(.disabled),
.dropdown-menu li:hover > .dropdown-item {
  color: var(--primary2);
}

.cs-offcanvas-body .dropdown-toggle:not(.dropdown-toggle-split)::after {
  position: absolute;
  right: 10px;
}

.navbar-nav i.fe-menu {
  font-size: 20px;
}

.topbar-link {
  line-height: 100% !important;
}

body .footer-address .card {
  background-color: transparent;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

body .footer-address .accordion-heading > a.collapsed,
body .footer-address .accordion-heading > a {
  padding: 0.5rem 0;
  color: rgba(255, 255, 255, 0.7);
}

body .footer-address .accordion-heading > a:hover {
  color: white;
}

body .footer-address .card-body {
  padding: 0;
}

.navbar-nav i.fe-home {
  font-size: 20px;
}

span.dropdown-divider {
  display: block;
}

.faq-title {
  color: var(--primary2);
  border-bottom-color: var(--primary2) !important;
}

.faq-toggle .accordion-heading > a {
  padding: 18px 15px;
  font-weight: 400;
  font-size: 1.125rem;
}

.faq-toggle .accordion-heading > a i {
  margin-top: 5px;
}

.faq-toggle > .card .card-body {
  padding-left: 35px;
  padding-top: 15px;
}

span.flt {
  padding-right: 40px;
}

.faqb .accordion > .card {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.085);
  border-radius: 0;
  margin-bottom: 0;
}

.form-control {
  color: #737491;
}

button.close.p-2[data-toggle="search"] {
  margin-left: 5px;
}
a.btn-social {
  text-decoration: none;
}

/*-----responsive-------*/
@media (max-width: 575px) {
  .btn.toggle-dn.ml-3 {
    margin-left: 5px !important;
  }
  .topbar a.social-btn {
    margin-right: 4px !important;
  }
  .card-holder-name {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .order-table,
  .order-table thead,
  .order-table tbody,
  .order-table th,
  .order-table td,
  .order-table tr {
    display: block;
  }
  .order-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .order-table tr {
    border: 1px solid #e9e9f2;
    margin-bottom: 15px;
  }
  .order-table th,
  .order-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .order-table th:before,
  .order-table td:before {
    position: absolute;
    top: 12px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-column);
    color: #4a4b65;
    font-weight: 600;
    font-size: 0.875rem !important;
  }
  a.receipt-link {
    font-size: 14px;
    font-weight: 500 !important;
  }
  .order-table th,
  .order-table td {
    font-weight: 500 !important;
  }
  .order-table tbody th,
  .order-table thead th:first-child {
    border-radius: 5px 0 0 0;
    border-left: none;
  }
  .order-table tbody td:last-child,
  .order-table thead th:last-child {
    border-right: none;
    border-radius: 0 0 5px 0;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .mm-1c .dropdown:hover > .dropdown-menu {
    display: block !important;
  }
}
