/* @import url("./fonts.css"); */
/* @import url("./bahij-fonts.css"); */

/* starting my default style */
/* site main colors  */

:root {
  --primary: #1768b3;
  --primary2: #0e8bff;
  --light-primary: rgba(23, 104, 179, 0.5);
  --light-trans-primary: rgba(23, 104, 179, 0.22);
  --trans-primary: rgba(23, 104, 179, 0.8);
  --active-primary: #12538f;
  --secondary: #008796;
  --form-control-color: #737491;
  --yellow: #ffab19;
  --white: #ffffff;
  --black: #15202b;
  --purple: #6c207b;
  --white-trans: rgba(255, 255, 255, 0.1);
  --light-white: #f8f8f8;
  --light-gray-rgba: rgba(172, 172, 172, 0.5);
  --green: #05a19c;
  --red: #f74f78;
  --gray: #5a5b75;
  --main-shadow: 2px 2px 15px -5px rgba(0, 0, 0, 0.75);
  --shadowHover: 2px 2px 25px -5px rgba(0, 0, 0, 0.95);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --mainTrans: 0.5s ease all;
  --mainHover: rgba(0, 173, 238, 0.3);
  --overlay: rgba(19, 62, 73, 0.7);
  --darkoverlay: rgba(19, 62, 73, 0.9);
  --gryish-bg: #f5f5f5;
  --init-shadow: 0 0px 8px -3px #000;
  --nav-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
  --main-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07),
    0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  --main-gray: #a1a1aa;
  --light-gray: #e9e9f2;
  --dark-gray: #4a4b65;
  --body-gray: #737491;
  --light-white: #f7f7fc;
  --sidebar-bg: #f7f7fc;
  --svg-logo-loader: #1768b3;
  --feed-bg: #f7f7f8;
  /* --border: rgba(0, 0, 0, 0.085); */
  --tab-label-color: rgba(0, 0, 0, 0.54);
  --primary-transparent: rgba(255, 255, 255, 0.9);
}

html {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

/* making our footer stick to bottom !!!! */
main {
  margin: 0;
  min-height: 100vh !important;
  height: 100%;
  /* min-height: -webkit-fill-available; */

  display: flex;
  flex-direction: column;
}
body {
  /* background-size: 100%; */
  overscroll-behavior-y: none;
  /* -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.content-wrap {
  flex: 1;
}
@media (max-width: 991.98px) {
  .content-wrap {
    padding-top: 3.5rem !important;
  }
}

.object-fit-cover {
  object-fit: cover;
}
.transition-none {
  transition: none;
}
.adjust-text-0-col {
  display: none !important;
}
.adjust-text-1-col {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.adjust-text-2-col {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.adjust-text-3-col {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.adjust-text-4-col {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.adjust-text-5-col {
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-shadow {
  box-shadow: 0 1px 2px var(--shadow-2);
}
.nav-link-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.has-link a {
  width: 100% !important;
  color: var(--gray);
  display: block;
}
.hover-color-unset,
.focus-color-unset {
  color: inherit;
}
.hover-color-unset:hover,
.focus-color-unset:focus {
  color: unset !important;
  text-decoration: unset;
}
.color-unset {
  color: unset !important;
}
.has-link a:hover {
  color: var(--primary2);
  text-decoration: none;
}
.has-link a.active {
  pointer-events: none;
  cursor: default;
  color: var(--primary2);
}

.flex-1 {
  flex: 1 !important;
}
.flex-2 {
  flex: 2 !important;
}
.cursor {
  cursor: pointer !important;
}
.bg-light-primary {
  background-color: var(--light-primary);
}
.bg-skeleton {
  background-color: #e4e6eb;
}
.bg-google {
  border: 1px solid #ea4335 !important;
  color: #ea4335 !important;
}
.bg-google:hover {
  background-color: #ea4335;
  color: #fff !important;
}
.bg-apple {
  border: 1px solid #000 !important;
  color: #000 !important;
}
.bg-apple:hover {
  background-color: #000;
  color: #fff !important;
}
.bg-apple-DM {
  border: 1px solid #fff !important;
  color: #fff !important;
}
.bg-apple-DM:hover {
  background-color: #fff;
  color: #000 !important;
}

.bg-twitter {
  border: 1px solid #1da1f2 !important;
  color: #1da1f2 !important;
}
.bg-twitter:hover {
  background-color: rgba(29, 161, 242, 0.9);
  color: #fff !important;
}
.bg-facebook {
  border: 1px solid #0866ff !important;
  color: #0866ff !important;
}
.bg-facebook:hover {
  background-color: #0866ff;
  color: #fff !important;
}
.btn-light-primary {
  background-color: var(--light-primary);
  /* border-color: var(--light-primary); */
  color: #fff !important;
}
.btn-light-primary:hover {
  color: #fff;
  opacity: 0.9;
}
.gray {
  color: var(--gray);
}
.light-border {
  border: 1px solid var(--light-gray) !important;
}

/* adding my custom classes */
.primary-hover:hover {
  color: var(--primary2) !important;
}
.danger-hover:hover {
  color: #f74f78 !important;
}
.danger-hover.active {
  color: #f74f78 !important;
}

.yellow-hover:hover {
  color: var(--yellow) !important;
}
.yellow-hover.active {
  color: var(--yellow) !important;
}
/* .toast-postition {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2000;
} */
.light-border.active {
  background-color: var(--primary2) !important;
  color: #fff !important;
}
.text-gray {
  color: var(--gray) !important;
}
.v-centered {
  display: flex !important;
  align-items: center;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 992px) {
  .hide-lg {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .cs-offcanvas-collapse {
    z-index: 1080;
  }
}

.cs-offcanvas {
  z-index: 1080;
}

@media (min-width: 992px) {
  .cs-sidebar-toggle {
    display: none;
  }
}

/* loading styles */
.cs-page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s 0.2s ease-in-out;
  transition: all 0.4s 0.2s ease-in-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}

.cs-page-loading.active {
  opacity: 1;
  visibility: visible;
}

.cs-page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.cs-page-loading.active > .cs-page-loading-inner {
  opacity: 1;
}

.cs-page-loading-inner > span {
  display: block;
  font-family: var(--font-sans-serif);
  font-size: 1rem;
  font-weight: normal;
  color: #737491;
}

.cs-page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: 0.75rem;
  vertical-align: text-bottom;
  border: 0.15em solid #766df4;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
}

@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* overwritting some styles */
.navbar-tool-icon-box {
  cursor: pointer;
}
.navbar-box-shadow-top {
  box-shadow: 0 -0.2rem 0.625rem -0.1rem rgba(0, 0, 0, 0.1);
  /* 0 0.125rem 0.625rem -0.1875rem rgba(0,0,0,0.1) */
  /* rgba(9, 30, 66, 0.2) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */
}

@media (max-width: 991.98px) {
  .navbar-floating {
    top: 0;
    padding-top: 30px !important;
    transform: translate(0, -30px);
    position: fixed;
  }
  .navbar-sticky.navbar-stuck {
    animation: none;
  }
}
.pt30 {
  @media (max-width: 991.98px) {
    padding-top: 30px !important;
  }
}
.cs-widget-link {
  width: 100%;
}
.widget-link-arrow-hidden::before {
  visibility: hidden;
}

.footer-address .card-body,
.footer-address .card-header {
  z-index: unset;
}
.dropdown-item.active span,
.dropdown-item.active i {
  color: var(--primary2) !important;
}
.hide-dropdown-arrow .dropdown-toggle::after {
  display: none;
}

.st1 {
  fill: url(#SVGID_1_);
}
.st2 {
  fill: url(#SVGID_2_);
}
.st3 {
  fill: url(#SVGID_3_);
}
.st4 {
  fill: url(#SVGID_4_);
}
.st5 {
  fill: url(#SVGID_5_);
}
.st6 {
  fill: url(#SVGID_6_);
}
.st7 {
  fill: url(#SVGID_7_);
}
.st8 {
  fill: url(#SVGID_8_);
}
.st9 {
  fill: url(#SVGID_9_);
}
.st10 {
  fill: url(#SVGID_10_);
}
.st11 {
  fill: url(#SVGID_11_);
}
.st12 {
  fill: url(#SVGID_12_);
}
.st13 {
  fill: url(#SVGID_13_);
}
.st14 {
  fill: url(#SVGID_14_);
}
.st15 {
  fill: url(#SVGID_15_);
}
.st16 {
  fill: url(#SVGID_16_);
}
.st17 {
  fill: url(#SVGID_17_);
}
.st18 {
  fill: url(#SVGID_18_);
}
.st19 {
  fill: url(#SVGID_19_);
}
.st20 {
  fill: url(#SVGID_20_);
}
.st21 {
  fill: url(#SVGID_21_);
}
.st22 {
  fill: url(#SVGID_22_);
}
.st23 {
  fill: url(#SVGID_23_);
}
.st24 {
  fill: url(#SVGID_24_);
}
.st25 {
  fill: url(#SVGID_25_);
}

/* .stop-color1{
    stop-color:var(--primary2);
    
  }
  .stop-color2{
    stop-color:var(--primary)
  }
  .stop-color{
    enable-background:new 0 0 268.42 249.62;
  } */

.table th,
.table td {
  border-top: none;
}

#nprogress .bar {
  /* NPROGRESS COLOR */
  /* background: #FFF !important; */
  background: var(--light-primary) !important;
}
#nprogress .peg {
  display: none !important;
}
.donate-card {
  background-image: url("https://cdn.molhamteam.com/assets/img/white-splash-small.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.donate-card img {
  pointer-events: none;
  position: absolute !important;
  height: 100% !important;
  z-index: 0 !important;
}

/* swiper carousel custom styles */

/* .swiper-container {
  transition: var(--mainTrans)  !important;
} */

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  transition: var(--mainTrans) !important;
}

.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
  display: flex !important;
  /* transform: translateX(1rem); */
  animation: 1s arrowAni;
}

@keyframes arrowAni {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: flex !important;
    /* color: #fff !important;
    transition: var(--mainTrans)  !important; */
  }
  .swiper-container:hover .swiper-button-prev,
  .swiper-container:hover .swiper-button-next {
    display: flex !important;
    /* transform: translateX(1rem); */
    animation: unset;
  }
}

/* toastify custom styles */
.Toastify__close-button {
  align-self: center !important;
  /* padding: 6px !important;  */

  background: rgba(0, 0, 0, 0.2) !important;

  border-radius: 50%;

  /* display: block; */
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 0;
  margin-right: 6px !important;
}

.Toastify__close-button:hover {
  opacity: 0.6;
}

.Toastify__toast {
  border-radius: 12px !important;
}
/* .Toastify__toast--success {
  background-color: #16c995 !important;
} */
.Toastify__toast--info {
  background-color: #6a9bf4 !important;
}
.Toastify__toast--warning {
  background-color: #ffb15c !important;
}
/* .Toastify__toast--error {
  background-color: #f74f78 !important;
} */
.Toastify__toast-body {
  width: 85%;
}
@media (max-width: 991.98px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    top: 0 !important;
    bottom: unset !important;
  }
  .Toastify__toast {
    border-radius: 0 !important;
  }
}
/*  */
.card-body-wrapper h6 {
  padding: 10px 0;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--dark-gray);
}
.card-body-wrapper .text-muted {
  color: var(--main-gray) !important;
}
.card-body-wrapperspan {
  font-size: 15px;
  font-weight: 400;
}

.modal-header .back,
button.close,
button.back {
  position: relative;
  background: var(--white-trans);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  vertical-align: middle;
  padding-top: 3px;
  padding-right: 1px;
  transition: opacity 0.15s ease-in-out;
  opacity: 0.7;
  line-height: 1;
  color: var(--dark-gray);
  text-shadow: none;
  font-size: 1.4rem !important;
}

.modal-header .back i,
button.close i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.modal-header .back:hover,
button.close:hover,
button.back:hover {
  opacity: 1;
}

.modal-backdrop {
  z-index: 2000;
}
.modal-header {
  padding: 14px 1rem;
  background-color: var(--white);
}
.modal-title.h4 {
  font-size: 1.125rem;
}
.date-picker-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-picker-modal .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  width: unset;
}
.date-picker-modal .modal-body {
  margin: auto;
  padding: 0;
}
.date-picker-modal .react-datepicker {
  font-size: 1.2rem !important;
}

.donate-modal {
  z-index: 2000 !important;
}
/* react code input */

@media (max-width: 1000px) {
  .styles_react-code-input__CRulA input {
    width: 50px !important;
    height: 50px !important;
    margin: 0 5px !important;
  }
}

/* masonery styles */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

/* react datepicker styles */

.react-datepicker {
  font-family: inherit !important;
  font-size: 1em;
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  border-radius: 1rem !important;
  display: inline-block !important;
  position: relative !important;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px !important;
}
.react-datepicker__header {
  border-radius: 1rem 1rem 0 0 !important;
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  border: 1px solid var(--light-gray) !important;
  padding: 0.6em 1.4em !important;

  margin: 0 !important;
  border-radius: 0 0 1rem 1rem !important;
}
.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__day--selected {
  background-color: var(--primary2) !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary2) !important;
  color: #fff !important;
}
.react-datepicker__day--selected:hover {
  background-color: var(--light-primary) !important;
}
.react-datepicker__header {
  text-align: center;
  background-color: var(--primary2) !important;
  border-bottom: 1px solid var(--primary2) !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
}
.react-datepicker__navigation--next {
  border-left-color: #fff !important;
}
.react-datepicker__current-month {
  color: #fff !important;
  font-size: 1em !important;
}

.react-datepicker__input-container {
  width: inherit !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__day:hover {
  background-color: var(--primary2) !important;
  color: #fff !important;
}
.react-datepicker__day--disabled:hover {
  color: #ddd !important;
}
.react-datepicker__navigation--previous {
  background-image: url("/arrow-left.svg") !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
}
.react-datepicker__navigation--next {
  background-image: url("/arrow-right.svg") !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
}

.react-datepicker__day--disabled {
  color: #ddd !important;
}
.react-datepicker__day--disabled:hover {
  cursor: not-allowed;
  background: transparent !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
}

/* simplebar custom styles */
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: var(--dark-gray) !important;
  opacity: 0.4 !important;
  width: 5px !important;
  display: none;
}
.react-datepicker__header__dropdown--select {
  padding: 0.75rem 0.5rem;
  display: flex;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  display: block;
  width: 100%;
  padding: 0.3rem 2.25rem 0.3rem 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #f5f5f5;
  background-color: var(--light-primary);
  outline: none;
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  background-size: 16px 12px;
  border: 1px solid #dfdfeb;
  /* border-radius: 0.75rem; */
  /* box-shadow: inset 0 1px 2px transparent; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.react-datepicker__month-select {
  border-radius: 0.5rem 0 0 0.5rem;
}
.react-datepicker__year-select,
.react-datepicker__month-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.react-datepicker__year-select {
  border-radius: 0 0.5rem 0.5rem 0;
}
.react-datepicker__month-dropdown-container--select {
  margin: 0 !important;
}
.react-datepicker__month-dropdown-container--select {
  flex: 1 1 auto !important;
}
.invalid .invalid-feedback {
  display: block;
}
/* .invalid-feedback:not(.has-no-btn) {
  text-align: right;
} */

/* react-select custom styles */
.css-yk16xz-control,
.css-1pahdxg-control {
  border-radius: 1rem !important;
  padding: 0.25rem 1.125rem !important;
  border-color: #dfdfeb !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
}

/* react-phone-number-input custom styles */

.PhoneInput {
  width: 100%;
  position: relative;
}
.PhoneInputInput {
  outline: none;
  border: none;
  border-radius: 0.75rem;
  width: 100%;
  padding: 0.5625rem 1.125rem;
  border: 1px solid #dfdfeb;
}

.PhoneInputCountry {
  position: absolute !important;
  right: 1.125rem;
  top: 0;
  bottom: 0;
  vertical-align: middle;
}
.PhoneInputInput:-webkit-autofill .PhoneInputCountry,
.PhoneInputInput:-internal-autofill-selected .PhoneInputCountry {
  color: #2b3944 !important;
}
.PhoneInputCountryIcon--border {
  position: relative;
}

.PhoneInputCountrySelectArrow {
  margin: 10px;
}
/* react-verification-code-input custom styles */

.styles_react-code-input__CRulA {
  display: flex;
  justify-content: center;
}
.styles_react-code-input-container__tpiKG {
  width: 100% !important;
  direction: ltr !important;
}
.styles_react-code-input__CRulA input {
  margin: 0 10px;
  border: 1px solid #525461 !important;
  border-radius: 1rem !important;
  font-family: inherit !important;
}
.styles_react-code-input__CRulA input:focus {
  border: 1px solid var(--primary2) !important;
}

/* stripe custom style */
.InputElement.Input {
  color: red;
}

.form-control.StripeElement--invalid {
  border-color: var(--red) !important;
}
.form-control.StripeElement--focus {
  border-color: var(--primary2) !important;
  box-shadow: 0 0 0 0 transparent,
    0 0.375rem 0.625rem -0.3125rem rgb(118 109 244 / 15%);
}

/* material styles */

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

/* rc tabs */

.rc-tabs-tab.rc-tabs-tab-active {
  /* background: red; */
  color: var(--primary);
}
.rc-tabs-ink-bar {
  background: var(--primary) !important;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active:hover {
  color: var(--primary) !important;
}
.rc-tabs-tab:hover {
  color: var(--primary) !important;
}

.rc-tabs-top .rc-tabs-tab-prev,
.rc-tabs-top .rc-tabs-tab-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  font-display: swap !important;
}

/* rts custom styles */

.rts___tabs {
  padding: 0 !important;
}
.rts___tab {
  margin: 0 !important;
  position: relative;
}
.rts___nav___btn svg {
  max-width: unset;
}

.rts___btn {
  border-radius: unset !important;
  border: none !important;
}
.rts___tab___selected {
  background-color: transparent !important;
  box-shadow: none !important;
  /* border-bottom: 3px solid var(--primary) !important; */
}
.rts___tab___selected a {
  color: var(--primary2) !important;
}
.rts___tab span {
  margin: auto;
  height: 3px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.rts___tab___selected span {
  background: var(--primary2);
  width: 100%;
}
.rts___nav___btn:hover {
  background-color: transparent !important;
}
.rts___nav___btn {
  color: var(--gray) !important;
}
.rts___nav___btn:hover > svg {
  stroke: var(--gray) !important;
}
button:disabled.rts___btn,
button[disabled].rts___btn svg {
  stroke: var(--main-gray) !important;
}
@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 0 !important;
  }
}
@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px !important;
  }
}
/* ending rts styles */

.info-bubble {
  position: relative;
  border-radius: 0.5rem;
}
.info-bubble::before {
  content: "";
  display: block;
  position: absolute;
  left: unset;
  right: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: var(--light-gray);
}
.info-bubble::after {
  content: "";
  display: block;
  position: absolute;
  left: unset;
  right: 50%;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: var(--white);
}

#footer-nav {
  display: none;
  position: fixed;
  width: 100%;
  z-index: 2000;
  left: 0;
  bottom: 0;

  /* background-color: var(--white); */
  height: 5.9rem;
}
@supports (-webkit-touch-callout: none) {
  #footer-nav {
    height: 6.6rem;
  }
}
.offcanvas {
  outline: none !important;
}
/* .offcanvas-body::-webkit-scrollbar {
  display: none;
}
.offcanvas-body:hover::-webkit-scrollbar {
  display: block;
} */

.offcanvas-body::-webkit-scrollbar {
  /* width: 0.3rem;
  background-color: transparent;
  opacity: 0;
  transition: color 1s; */
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #dfdfeb;
  border-radius: 0.25rem;
}

.offcanvas-body > .simplebar-track {
  display: block;
  background-color: transparent;
}

.offcanvas-body .simplebar-vertical {
  margin-right: 0.1875rem;
}

.offcanvas-body:active::-webkit-scrollbar-thumb,
.offcanvas-body:focus::-webkit-scrollbar-thumb,
.offcanvas-body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  transition: color 1s;
}
.offcanvas-body::-webkit-scrollbar-thumb {
  visibility: hidden;
  transition: color 1s;
}
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar-none {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scrollbar-none::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}
.widget ul > li ul > li,
.widget ul > li ol > li,
.widget ol > li ul > li,
.widget ol > li ol > li {
  border-color: var(--light-gray) !important;
}
.widget ul > li ul > li > a::after,
.widget ul > li ol > li > a::after,
.widget ol > li ul > li > a::after,
.widget ol > li ol > li > a::after {
  background-color: var(--light-gray);
}

.custom-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  font-size: 1rem;
  list-style: none;
  background-color: transparent;
}

.custom-breadcrumbs li + li {
  padding-left: 0.5rem;
  /* padding-left: ${({ theme }) => (theme.rtl ? "0" : "0.5rem")};
  padding-right: ${({ theme }) => (theme.rtl ? "0.5rem" : "0")}; */
}

.custom-breadcrumbs li + li::before {
  float: left;
  padding-right: 0.5rem;
  color: #b0b3b8;
  content: var(--bs-breadcrumb-divider, "")
    /* rtl: var(--bs-breadcrumb-divider, "") */;
  /* transform: ${({ theme }) => (theme.rtl ? "rotate(180deg)" : "0")}; */
}

.custom-breadcrumbs li.active {
  color: #b0b3b8;
}
.custom-breadcrumbs li {
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 0;
}

.custom-breadcrumbs li::before {
  margin-top: 0;
  font-family: "around-icons";
  font-size: 1.2em;
  font-weight: normal;
  vertical-align: middle;
}

.custom-breadcrumbs li > a {
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;
  color: var(--gray);
}

.custom-breadcrumbs li > a:hover {
  color: var(--primary2);
  text-decoration: none;
}

.custom-breadcrumbs li > a > i {
  margin-top: -0.0625rem;
  margin-right: 0.325rem;
  font-size: 1.05em;
  vertical-align: middle;
}

.custom-breadcrumbs li.active {
}
.custom-breadcrumbs li a {
  /* color: var(--primary) !important; */
}
.jumbtron-img {
  width: 140px;
  height: 140px;
}
@media (max-width: 767.98px) {
  .jumbtron-img {
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }
}

.bg-blur {
  backdrop-filter: blur(17px);
  background-color: var(--primary-transparent) !important;
}

.bg-trans {
  background-color: transparent !important;
}

.cropper-canvas {
  position: relative;
}
.cropper-canvas .img-cropper-edit-btn {
  position: absolute;
  right: -12px;
  top: -12px;
}
.custom-conffetti {
  z-index: 9999 !important;
}
.donate-tab {
  /* padding: 0 !important; */
  margin: 0 1rem !important;
  min-width: unset !important;
}
.donate-tab:first-of-type {
  /* margin-left: 0.5rem !important;
  margin-right: 1rem !important; */
}

.profile-menu-item-avatar {
  width: 64px;
  height: 64px;
}
@media (max-width: 767.98px) {
  .profile-menu-item-avatar {
    /* min-width: 50px; */
    width: 50px;
    height: 50px;
  }
}
.rendered-p p {
  margin: 0;
}

.filter-label {
  color: var(--dark-gray);
  font-weight: 500;
}

.ps-45 {
  padding-left: 2.3rem !important;
}

.test-normalize {
  -ms-flex-align: stretch;
  -ms-flex-direction: column;
  -ms-flex-negative: 0;
  -ms-flex-preferred-size: auto;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-basis: auto;
  -webkit-flex-direction: column;
  -webkit-flex-shrink: 0;
  align-items: stretch;
  border: 0 solid black;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-height: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 0;
}

.parent_noramlize {
  backface-visibility: hidden;
  right: 0px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 99;
}

/* animate.css transitions */

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);

    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes toastSlideUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0);
    opacity: 0;
  }
}
@keyframes toastSlideUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, -110%, 0);
    transform: translate3d(0, -110%, 0);
    opacity: 0;
  }
}
.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-out {
  -webkit-animation: fade-out 0.3s ease-out both;
  animation: fade-out 0.3s ease-out both;
}
/* bootstrap 5 offcanvas styles */
.offcanvas {
  position: fixed;
  bottom: 0;
  /* z-index: 1050; */
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: var(--white);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 2000;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-body {
  flex-grow: 1;
  /* padding: 1rem 1rem; */
  overflow-y: overlay;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 22rem;
  /* border-right: 1px solid rgba(0, 0, 0, 0.2); */
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 22rem;
  /* border-left: 1px solid rgba(0, 0, 0, 0.2); */
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  /* height: 30vh; */
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  /* height: 30vh; */
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}
@media (max-width: 991.98px) {
  .offcanvas {
    border: none;
  }
  .offcanvas-header {
    padding: 1rem 1.5rem;
  }
}

/* end offcanvas styles */

@media (max-width: 991.98px) {
  .hide-donate-tabs-border {
    border-bottom: none !important;
    /* box-shadow: none !important; */
  }
}

@media (max-width: 991.98px) {
  .Toastify__zoom-enter {
    -webkit-animation-name: fadeInDown !important;
    animation-name: fadeInDown !important;
  }
  .Toastify__zoom-exit,
  .Toastify__toast-container--bottom-left {
    -webkit-animation-name: toastSlideUp !important;
    animation-name: toastSlideUp !important;
  }
}

.btn-text-color:hover {
  color: var(--primary) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
  -webkit-text-fill-color: var(--dark-gray) !important;
  /* background */
  background-color: var(--input-gray) !important;
}
.Toastify__toast-theme--dark {
  background-color: #1b2733 !important;
}

.smol-input {
  font-size: 0.875rem;
}

.z-2002 {
  z-index: 2002 !important;
}

.swiper-handler-min-height {
  min-height: 50px !important;
}
.numbered-list {
  list-style-type: decimal !important;
}
.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 3%;
  width: 100%;
  height: 2px;
  background: var(--dark-gray);
}

.event {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--dark-gray);
  position: relative;
  z-index: 1;
}

.timeline-desc {
  padding-left: 0 !important;
  text-align: center !important;
  font-size: 0.875rem !important;
}
.timeline-date {
  margin-top: 0.25 !important;
  text-align: center !important;
}
@media (max-width: 600px) {
  .timeline-desc {
    font-size: 0.875rem !important;
    text-align: unset !important;
  }
  .timeline-date {
    margin-top: 0rem !important;
    text-align: unset !important;
  }
  .timeline {
    flex-direction: column;
    align-items: normal;
  }

  .timeline::before {
    top: 0;
    left: 1.5%;
    width: 2px;
    height: 100%;
  }

  .event {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px 0;
  }

  .dot {
    margin-right: 10px;
  }
}
.rc-anchor-dark {
  background: red !important;
}
@keyframes scaleToLeftAndBack {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
}

.scaleRightAndBack {
  animation: scaleToLeftAndBack 1s ease-in-out forwards;
}

.slide-up {
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
}
.slide-up-hide {
  transform: translateY(100%);
}

/* nextjs-portal {
  display: none;
} */
